<template>
    <div class="addUpdateOntime">
        <jy-dialog type="form" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogWidth">
            <div>
                <el-form :model="diaform" ref="dialogForm" :rules="rules" :disabled="isCheck" :label-width="formLabelWidth">
                    <el-form-item prop="routeName" label="线路组织:">
                        <el-input placeholder="请选择" :disabled="isUpdate||isCheck" v-model="diaform.routeName" @focus="selectInstitutions"></el-input>
                    </el-form-item>
                    <div class="periodTimeTip" v-text="periodTimeText" :style="{'margin-left':formLabelWidth}"></div>
                    <el-form-item label="选择时段:" prop="timeFrame">
                        <el-select v-model="diaform.timeFrame" placeholder="请选择" @change="periodTimeChange" :disabled="isUpdate||isCheck">
                            <el-option v-for="item in periodTimeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="日期类型:" prop="dateType" :disabled="isUpdate||isCheck">
                        <el-radio v-model="diaform.dateType" label="1" :disabled="isUpdate||isCheck">全部</el-radio>
                        <el-radio v-model="diaform.dateType" label="2" :disabled="isUpdate||isCheck">工作日</el-radio>
                        <el-radio v-model="diaform.dateType" label="3" :disabled="isUpdate||isCheck">周末</el-radio>
                    </el-form-item>
                    <el-form-item label="方向:" prop="dir">
                        <el-select v-model="diaform.dir" placeholder="请选择" :disabled="isUpdate||isCheck">
                            <el-option v-for="item in directionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="发车可提前时长:" prop="aheadTime">
                        <el-input type="number" v-model="diaform.aheadTime">
                            <template slot="append">分</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="发车可延后时长:" prop="delayTime">
                        <el-input type="number" v-model="diaform.delayTime">
                            <template slot="append">分</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="备注:" prop="remark">
                        <el-input v-model="diaform.remark" type="textarea" :rows="4" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="!isCheck">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button type="primary" @click="save('dialogForm')">确 定</el-button>
                </div>
            </div>
        </jy-dialog>
        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" url="/waybillOperate/getTree" title="选择线路组织" :defaultProps="props"></institutions-tree>
    </div>
</template>
<script>
import institutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'

export default {
    data() {
        return {
            type: '',
            isCheck: false,
            title: '',
            periodTimeList: [{
                    value: '1',
                    label: '全天'
                },
                {
                    value: '2',
                    label: '早高峰'
                },
                {
                    value: '3',
                    label: '晚高峰'
                },
                {
                    value: '4',
                    label: '低峰'
                }
            ],
            periodTimeText: '',
            props: {
                children: 'children',
                label: 'text',
                value: 'id',
                // multiple: true,
                checkStrictly: true
            },
            directionList: [{
                    value: '1',
                    label: '全部'
                },
                {
                    value: '2',
                    label: '上行'
                },
                {
                    value: '3',
                    label: '下行'
                }
            ],
            diaform: {
                timeFrame: '1',
                routeId: '',
                routeName: '',
                dateType: '1',
                dir: '1',
                aheadTime: '0',
                delayTime: '0',
                remark: ''
            },
            rules: {
                timeFrame: [{
                    required: true,
                    message: '请选择时段',
                    trigger: ['blur', 'change']
                }],
                routeName: [{
                    required: true,
                    message: '请选择线路组织',
                    trigger: ['blur', 'change']
                }]
            },
            dialogVisible: false,
            formLabelWidth: '150px',
            isUpdate: false,
        }
    },
    components: { institutionsTree },
    activated() {},
    created() {},
    watch: {
        type: function(val) {
            let t = ''
            let isCheck = false
            switch (val) {
                case 'add':
                    t = '新增发车准点参数设置'
                    break
                case 'update':
                    t = '修改发车准点参数设置'
                    this.isUpdate = true;
                    break
                case 'view':
                    t = '查看发车准点参数设置'
                    isCheck = true
                    break
                default:
                    t = '新增发车准点参数设置'
            }
            this.isCheck = isCheck
            this.title = t
        },
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('dialogForm')
                this.periodTimeText = ''
                this.isUpdate = false;
                this.type = '';
            }
        }
    },
    methods: {
        init(option, type) {
            this.type = type ? type : 'add'
            this.dialogVisible = true
            if (option) {
                this.$nextTick(() => {
                    if (this.type != 'add') {
                        //更新或查看
                        this.diaform = {
                          ...this.diaform,
                          ...JSON.parse(JSON.stringify(option))
                        }
                    } else {
                        //新增
                        let addOption = JSON.parse(JSON.stringify(option))
                        this.diaform.routeId = addOption.routeId
                        this.diaform.routeName = addOption.routeName
                    }
                    this.periodTimeChange()
                })
            }
        },
        // 组织
        selectInstitutions() {
            this.$refs.institutions.init()
        },
        addInstitutions(data) {
            //   console.log(data)
            this.diaform.routeId = data.id
            this.diaform.routeName = data.text
        },
        periodTimeChange() {
            //   let option = {}
            //   let url = '/system/org/queryOrgTree'
            //   this.$http.post(url, option).then(({ detail }) => {
            //     if (detail) {
            //       this.periodTimeText = detail
            //     } else {
            //       this.$message({
            //         type: 'warning',
            //         message: '【线路管理】早高峰时间段未设置，请设置！'
            //       })
            //     }
            //   })
            //   if (parseInt(this.diaform.timeFrame) == 2) {
            //     this.periodTimeText = '早高峰开始时间 06:30，早高峰结束时间 10:00'
            //   } else if (parseInt(this.diaform.timeFrame) == 3) {
            //     this.periodTimeText = '晚高峰开始时间 18:30，晚高峰结束时间 20:00'
            //   } else if (parseInt(this.diaform.timeFrame) == 4) {
            //     this.periodTimeText = '低峰开始时间 10:00，低峰结束时间 18:30'
            //   }
        },
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        cancel() {
            this.dialogVisible = false
            console.log('cancel')
        },
        save(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    let option = this.diaform
                    console.log(option)
                    let url = '/psdepontimeset/'
                    if (this.type == 'add') {
                        url = url + 'save'
                    } else {
                        url = url + 'update'
                    }
                    this.$http.post(url, option).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.msg
                        })
                        this.dialogVisible = false
                        this.$emit('updateList')
                    })
                } else {
                    return false
                }
            })
        }
    }
}

</script>
<style lang='scss' scoped>
.periodTimeTip {
    color: red;
    margin-bottom: 10px;
}

.timeText {
    display: inline-block;
    margin-right: 30px;
}

.jy_tabs {
    padding: 10px 0px 50px 20px;
}

</style>
